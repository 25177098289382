
import Records from '@/shared/services/records'
import { fieldFromTemplate } from '@/shared/helpers/poll'
import Flash from '@/shared/services/flash'
import EventBus from '@/shared/services/event_bus'
import Session        from '@/shared/services/session'
import AbilityService from '@/shared/services/ability_service'

import Vue     from 'vue'
import { uniq, map, sortBy, head, find, filter, sum } from 'lodash'
import { onError } from '@/shared/helpers/form'
import { format, formatDistance, parse, startOfHour, isValid, addHours, isAfter, parseISO } from 'date-fns'
import { hoursOfDay, exact} from '@/shared/helpers/format_time'

import RecipientsAutocomplete from '@/components/common/recipients_autocomplete'

export default
  components:
    RecipientsAutocomplete: RecipientsAutocomplete

  props:
    outcome: Object
    close: Function

  data: ->
    options: []
    bestOption: null
    isDisabled: false
    review: false
    isShowingDatePicker: false
    dateToday: format(new Date, 'yyyy-MM-dd')

  created: ->
    if @datesAsOptions()
      @options = map @outcome.poll().pollOptions(), (option) ->
        id:        option.id
        value:     exact(parseISO(option.name))
        attendees: option.stances().length

      @bestOption = head sortBy @options, (option) ->
        -1 * option.attendees # sort descending, so the best option is first

      Vue.set(@outcome, 'calendarInvite', true)

      @outcome.pollOptionId = @outcome.pollOptionId or @bestOption.id
      @outcome.customFields.event_summary = @outcome.customFields.event_summary or @outcome.poll().title

  methods:

    submit: ->
      @outcome.customFields.should_send_calendar_invite = @outcome.calendarInvite
      @outcome.customFields.event_description = @outcome.statement if @datesAsOptions()

      if @outcome.isNew()
        actionName = "created"
      else
        actionName = "updated"

      @outcome.save()
      .then (data) =>
        Flash.success("poll_common_outcome_form.outcome_#{actionName}")
        @closeModal()

      .catch onError(@outcome)

    datesAsOptions: ->
      fieldFromTemplate @outcome.poll().pollType, 'dates_as_options'

    newRecipients: (val) ->
      @recipients = val
      @outcome.recipientAudience = (val.find((i) -> i.type=='audience') || {}).id
      @outcome.recipientUserIds = map filter(val, (o) -> o.type == 'user'), 'id'
      @outcome.recipientEmails = map filter(val, (o) -> o.type == 'email'), 'name'

