
import EventBus from '@/shared/services/event_bus'
import Records from '@/shared/services/records'
import {map} from 'lodash'

export default
  props:
    discussion: Object

  data: ->
    readers: []

  mounted: ->
    Records.discussionReaders.fetch
      path: ''
      params:
        discussion_id: @discussion.id

    @watchRecords
      collections: ['discussionReaders']
      query: (records) =>
        @readers = Records.discussionReaders.collection.chain().
          find(discussionId: @discussion.id).simplesort('lastReadAt', true).limit(20).data()

  methods:
    openInviteModal: ->
      EventBus.$emit 'openModal',
        component: 'StrandMembersList',
        props: { discussion: @discussion }

