import { render, staticRenderFns } from "./new_discussion.vue?vue&type=template&id=6efd7af6&lang=pug&"
import script from "./new_discussion.vue?vue&type=script&lang=coffee&"
export * from "./new_discussion.vue?vue&type=script&lang=coffee&"
import style0 from "./new_discussion.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import AttachmentList from '@/components/thread/attachment_list.vue'
import DiscussionPrivacyBadge from '@/components/discussion/privacy_badge.vue'
import DocumentList from '@/components/document/list.vue'
import FormattedText from '@/components/common/formatted_text.vue'
import MidDot from '@/components/common/mid_dot.vue'
import StrandMembers from '@/components/strand/members.vue'
import StrandTitle from '@/components/strand/title.vue'
import TimeAgo from '@/components/common/time_ago.vue'
installComponents(component, {ActionDock,AttachmentList,DiscussionPrivacyBadge,DocumentList,FormattedText,MidDot,StrandMembers,StrandTitle,TimeAgo})
