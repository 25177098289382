
import EventBus from '@/shared/services/event_bus'
import Records from '@/shared/services/records'
import Session from '@/shared/services/session'
import Flash from '@/shared/services/flash'
import RecipientsAutocomplete from '@/components/common/recipients_autocomplete'
import StanceService from '@/shared/services/stance_service'
import {map, debounce, without, filter, uniq, uniqBy, some, find} from 'lodash'

export default
  components:
    RecipientsAutocomplete: RecipientsAutocomplete

  props:
    poll: Object

  data: ->
    stances: []
    membershipsByUserId: {}
    stanceUserIds: []
    reset: false
    saving: false
    initialRecipients: []
    actionNames: []
    service: StanceService
    query: ''

  mounted: ->
    @actionNames = ['makeAdmin', 'removeAdmin', 'revoke'] # 'resend'

    @fetchStances()
    @updateStances()

    @watchRecords
      collections: ['stances', 'memberships']
      query: (records) => @updateStances()

  computed:
    someRecipients: ->
      @poll.recipientAudience ||
      @poll.recipientUserIds.length ||
      @poll.recipientEmails.length

  methods:
    isAdmin: (stance) ->
      stance.admin || @membershipsByUserId[stance.participantId] && @membershipsByUserId[stance.participantId].admin

    isGuest: (stance) ->
      !@membershipsByUserId[stance.participantId]

    inviteRecipients: ->
      @saving = true
      Records.announcements.remote.post '',
        poll_id: @poll.id
        recipient_audience: @poll.recipientAudience
        recipient_user_ids: @poll.recipientUserIds
        recipient_emails: @poll.recipientEmails
      .then (data) =>
        console.log data
        count = data.stances.length
        Flash.success('announcement.flash.success', { count: count })
        @reset = !@reset
      .finally =>
        @saving = false

    newQuery: (query) ->
      @query = query
      @updateStances()
      @fetchStances()

    fetchStances: debounce ->
      Records.stances.fetch
        params:
          exclude_types: 'poll group'
          poll_id: @poll.id
          query: @query
      .then (records) =>
        userIds = map records['users'], 'id'
        Records.memberships.fetch
          params:
            exclude_types: 'group user'
            group_id: @poll.groupId
            user_xids: userIds.join('x')
    , 300

    updateStances: ->
      chain = Records.stances.collection.chain().
              find(pollId: @poll.id).
              find(revokedAt: null)

      if @query
        users = Records.users.collection.find
          $or: [
            {name: {'$regex': ["^#{@query}", "i"]}},
            {email: {'$regex': ["#{@query}", "i"]}},
            {username: {'$regex': ["^#{@query}", "i"]}},
            {name: {'$regex': [" #{@query}", "i"]}}
          ]
        chain = chain.find(participantId: {$in: map(users, 'id')})

      @stances = chain.data()
      @stanceUserIds = map(Records.stances.collection.find(pollId: @poll.id), 'participantId')

      @membershipsByUserId = {}
      Records.memberships.collection.find(userId: {$in: @stanceUserIds},
                                          groupId: @poll.groupId).forEach (m) =>
        @membershipsByUserId[m.userId] = m

