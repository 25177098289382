
import AppConfig      from '@/shared/services/app_config'
import Session        from '@/shared/services/session'
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import LmoUrlService  from '@/shared/services/lmo_url_service'
import InboxService   from '@/shared/services/inbox_service'

import { isUndefined, sortBy, filter, find, head, each, uniq, map, sum, compact,
         concat, intersection, difference, orderBy } from 'lodash'

export default
  data: ->
    organization: null
    open: false
    group: null
    version: AppConfig.version
    tree: []
    myGroups: []
    otherGroups: []
    organizations: []
    unreadCounts: {}
    expandedGroupIds: []
    openGroups: []
    unreadDirectThreadsCount: 0


  created: ->
    EventBus.$on 'toggleSidebar', => @open = !@open

    EventBus.$on 'currentComponent', (data) =>
      @group = data.group
      if @group
        @organization = data.group.parentOrSelf()
        @expandedGroupIds = [@organization.id]
      else
        @organization = null

    @watchRecords
      collections: ['groups', 'memberships', 'discussions']
      query: (store) =>
        @unreadDirectThreadsCount =
          Records.discussions.collection.chain().
                  find({groupId: null}).
                  where((thread) -> thread.isUnread()).data().length
        @updateGroups()

    EventBus.$on 'signedIn', (user) =>
      @fetchData()
      @openIfPinned()

    @fetchData() if Session.isSignedIn()

  mounted: ->
    @openIfPinned()

  watch:
    organization: 'updateGroups'

    open: (val) ->
      EventBus.$emit("sidebarOpen", val)

  methods:
    memberGroups: (group) ->
      group.subgroups().filter (g) -> g.membershipFor(Session.user())

    openIfPinned: ->
      @open = Session.isSignedIn() && !!Session.user().experiences['sidebar'] && @$vuetify.breakpoint.lgAndUp

    fetchData: ->
      Records.users.fetchGroups().then =>
        if @$router.history.current.path == "/dashboard" && Session.user().groups().length == 1
          @$router.replace("/g/#{Session.user().groups()[0].key}")

      InboxService.load()

    goToGroup: (group) ->
      @$router.push @urlFor(group)

    updateGroups: ->
      @organizations = compact(Session.user().parentGroups().concat(Session.user().orphanParents())) || []
      @openCounts = {}
      @closedCounts = {}
      @openGroups = []
      Session.user().groups().forEach (group) =>
        @openCounts[group.id] = filter(group.discussions(), (discussion) -> discussion.isUnread()).length
      Session.user().parentGroups().forEach (group) =>
        if @organization && @organization.id == group.parentOrSelf().id
          @openGroups[group.id] = true
        @closedCounts[group.id] = @openCounts[group.id] + sum(map(@memberGroups(group), (subgroup) => @openCounts[subgroup.id]))

    startOrganization: ->
     if AbilityService.canStartGroups()
      EventBus.$emit 'openModal',
        component: 'GroupNewForm',
          props: { group: Records.groups.build() }

    unreadThreadCount: ->
      InboxService.unreadCount()

    canViewPublicGroups: -> AbilityService.canViewPublicGroups()

  computed:
    user: -> Session.user()
    activeGroup: -> if @group then [@group.id] else []
    logoUrl: -> AppConfig.theme.app_logo_src

