
import Records from '@/shared/services/records'
export default
  props:
    model: Object

  mounted: ->
    @fetchCount()

  methods:
    fetchCount: ->
      Records.announcements.fetch 'size',
        params:
          recipient_audience: @model.recipientAudience
          recipient_user_ids: @model.recipientUserIds
          recipient_emails: @model.recipientUserIds
          "#{@model.constructor.singular}_id": @id
      .then (data) =>
        console.log data

