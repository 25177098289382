import { render, staticRenderFns } from "./chart_panel.vue?vue&type=template&id=5dea83b6&lang=pug&"
import script from "./chart_panel.vue?vue&type=script&lang=coffee&"
export * from "./chart_panel.vue?vue&type=script&lang=coffee&"
import style0 from "./chart_panel.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import PollMeetingTime from '@/components/poll/meeting/time.vue'
import UserAvatar from '@/components/user/avatar.vue'
installComponents(component, {PollMeetingTime,UserAvatar})
