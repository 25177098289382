import { render, staticRenderFns } from "./votes_panel.vue?vue&type=template&id=6b40ff00&lang=pug&"
import script from "./votes_panel.vue?vue&type=script&lang=coffee&"
export * from "./votes_panel.vue?vue&type=script&lang=coffee&"
import style0 from "./votes_panel.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import FormattedText from '@/components/common/formatted_text.vue'
import PollCommonStanceChoice from '@/components/poll/common/stance_choice.vue'
import UserAvatar from '@/components/user/avatar.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {FormattedText,PollCommonStanceChoice,UserAvatar,VBtn,VLayout,VSelect,VSpacer})
