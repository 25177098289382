





































import Records from '@/shared/services/records'

export default
  props:
    poll: Object

  data: ->
    pollOptionNames: ['agree', 'consent', 'abstain', 'disagree', 'block', 'objection'].filter (p) => @poll.pollOptionNames.includes(p)

  methods:
    countFor: (name) ->
      @poll.stanceData[name] or 0

    percentFor: (name) ->
      parseInt(parseFloat(@countFor(name)) / parseFloat(@poll.votersCount) * 100) || 0

    translationFor: (name) ->
      @$t("poll_proposal_options.#{name}")

