import { render, staticRenderFns } from "./announcement_history.vue?vue&type=template&id=284107bd&lang=pug&"
import script from "./announcement_history.vue?vue&type=script&lang=coffee&"
export * from "./announcement_history.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import DismissModalButton from '@/components/common/dismiss_modal_button.vue'
import MidDot from '@/components/common/mid_dot.vue'
import Space from '@/components/common/space.vue'
import TimeAgo from '@/components/common/time_ago.vue'
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {DismissModalButton,MidDot,Space,TimeAgo,VCard,VCardText,VCardTitle,VLayout,VProgressCircular,VSpacer})
